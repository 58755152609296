// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-fargning-av-fransar-och-bryn-tsx": () => import("../src/pages/fargning-av-fransar-och-bryn.tsx" /* webpackChunkName: "component---src-pages-fargning-av-fransar-och-bryn-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-klassisk-massage-tsx": () => import("../src/pages/klassisk-massage.tsx" /* webpackChunkName: "component---src-pages-klassisk-massage-tsx" */),
  "component---src-pages-lashlift-med-keratinbehandling-tsx": () => import("../src/pages/lashlift-med-keratinbehandling.tsx" /* webpackChunkName: "component---src-pages-lashlift-med-keratinbehandling-tsx" */)
}

